import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const AuthorEditModal = ({
  author,
  onEditAuthor,
  show,
  onClose,
  authorsIndexes,
  imageInputRef,
}) => {
  const password = localStorage.getItem("password");
  const { language } = useParams();

  const categoriesUrl = `${apiUrl}/categories/${language}/`;
  const [categories, setCategories] = useState([]);

  const [isFree, setIsFree] = useState(author.free || false);
  const [isCategoryValid, setIsCategoryValid] = useState(true);

  useEffect(() => {
    setIsFree(author.free || false);
  }, [author]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(categoriesUrl, {
          headers: { "X-Password": password },
        });
        const categories = categoriesResponse.data;
        setCategories(categories);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData().catch((error) => console.error("Error in fetchData:", error));
  }, [language, password, categoriesUrl]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const editedAuthorData = { id: author.id, free: isFree };

    const category = formData.get("category");
    if (!category || category === "") {
      setIsCategoryValid(false);
      return;
    } else {
      setIsCategoryValid(true);
    }

    let isImageUpdated = false;

    for (let [key, value] of formData.entries()) {
      if (key === "image" && value instanceof File && value.size > 0) {
        isImageUpdated = true;
        const reader = new FileReader();
        reader.onloadend = () => {
          editedAuthorData[key] = reader.result;
          submitData(editedAuthorData);
        };
        reader.readAsDataURL(value);
      } else if (key !== "image") {
        editedAuthorData[key] = value;
      }
    }

    if (!isImageUpdated) {
      submitData(editedAuthorData);
    }
  };

  const submitData = (data) => {
    onEditAuthor(data.id, data);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit author</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="editForm" onSubmit={handleSubmit}>
          <Container>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={author.name}
                  />
                </Form.Group>
              </Col>
              <Form.Group>
                <Form.Label>Prompt:</Form.Label>
                <Form.Control
                    type="text"
                    as='textarea'
                    rows={4}
                    name="prompt"
                    defaultValue={author.prompt}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Category:</Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    defaultValue={author.category || ""}
                    isInvalid={!isCategoryValid}
                  >
                    {categories.map((category) => (
                      <option key={category.id} value={category.name} selected={author.category === category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Control>
                  {!isCategoryValid && (
                    <Form.Control.Feedback type="invalid">
                      Please choose a category.
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 padding-top-20">
              <Col className="text-center">
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Free"
                    checked={isFree}
                    onChange={(e) => setIsFree(e.target.checked)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Current Image:</Form.Label>
                  <img src={author.image} alt={author.name} width="100%" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Upload New Image:</Form.Label>
                  <Form.Control
                    ref={imageInputRef}
                    type="file"
                    name="image"
                    label="Choose new image"
                    accept="image/*"
                  />
                </Form.Group>
              </Col>
            </Row>
            {Object.keys(author)
              .filter((key) => key.startsWith("link_"))
              .sort()
              .map((key) => (
                <Row className="mb-3" key={key}>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {authorsIndexes[key.replace("link_", "")]}:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name={key}
                        defaultValue={author[key]}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ))}

            <div className="button-container">
              <Button type="submit">Save</Button>
            </div>
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AuthorEditModal;
