import React from "react";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";

const CategoryEditModal = ({
  category,
  onSaveCategory,
  show,
  onClose,
  categoriesIndexes,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const editedCategory = { ...category };

    for (let [key, value] of formData.entries()) {
      editedCategory[key] = value;
    }

    onSaveCategory(editedCategory);
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="editForm" onSubmit={handleSubmit}>
          <Container>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={category.name}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Prompt:</Form.Label>
                  <Form.Control
                    type="text"
                    as='textarea'
                    rows={4}
                    name="new_prompt"
                    defaultValue={category.prompt}
                  />
                </Form.Group>
              </Col>
            </Row>
            {Object.keys(category)
              .filter((key) => key.startsWith("link_"))
              .sort()
              .map((key) => (
                <Row className="mb-3" key={key}>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {categoriesIndexes[key.replace("link_", "")]}:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name={key}
                        defaultValue={category[key]}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ))}

            <div className="button-container">
              <Button type="submit">Save</Button>
            </div>
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CategoryEditModal;
