import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form } from "react-bootstrap";


const AuthorTable = ({
  authors,
  setAuthors,
  onEditAuthor,
  onDeleteAuthor,
  updateSortData,
  onCheckboxChange,
  categories,
}) => {
  const [selectedIndex] = useState("01");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sortedAuthors, setSortedAuthors] = useState([]);

  useEffect(() => {
    let filteredAuthors = selectedCategory
      ? authors.filter((author) => author.category === selectedCategory.toLowerCase().replace(' ', '_'))
      : authors;

    const updatedSortedAuthors = filteredAuthors.slice().sort((a, b) => {
      const aValue = a[`sort_${selectedIndex}`] || 0;
      const bValue = b[`sort_${selectedIndex}`] || 0;
      return aValue - bValue;
    });

    setSortedAuthors(updatedSortedAuthors);
  }, [authors, selectedCategory, selectedIndex]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(sortedAuthors);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newAuthors = items.map((author, index) => ({
      ...author,
      [`sort_${selectedIndex}`]: index,
    }));

    setAuthors(newAuthors);
    updateSortData(
      newAuthors.reduce((acc, author) => {
        acc[author.id] = author[`sort_${selectedIndex}`];
        return acc;
      }, {}),
      selectedIndex
    );
  };

  return (
    <div className="container mt-5">
      <div className="table-responsive">
        {categories && Array.isArray(categories) && (
          <Form.Control
            as="select"
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="mb-3"
          >
            <option value="">Choose category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Control>
        )}
        {selectedCategory && (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="authors">
              {(provided) => (
                <table
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="table table-bordered border table-striped table-hover mt-4"
                >
                  <thead>
                    <tr>
                      <th className="text-center">Name</th>
                      <th className="text-center">Category</th>
                      <th className="text-center">Image</th>
                      <th className="text-center">Free</th>
                      <th className="text-center actions-column">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedAuthors.map((author, index) => (
                      <Draggable
                        key={author.id}
                        draggableId={author.id}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={author.id}
                          >
                            <td>{author.id}</td>
                            <td>{author.category}</td>
                            <td className="text-center">
                              <img
                                src={author.image}
                                alt={author.name}
                                width="260px"
                              />
                            </td>
                            <td className="text-center custom-checkbox">
                              <input
                                type="checkbox"
                                checked={author.free}
                                onChange={() =>
                                  onCheckboxChange(
                                    author.id,
                                    "free",
                                    !author.free
                                  )
                                }
                              />
                            </td>
                            <td className="text-center">
                              <FontAwesomeIcon
                                className="text-secondary mr-2"
                                icon={faEdit}
                                onClick={() => onEditAuthor(author)}
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default AuthorTable;
